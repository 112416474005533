(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:PersonLicense
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('PersonLicense', PersonLicense);

  function PersonLicense($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/persons/:personId/licences', {personId: '@_personId'});
  }
}());
